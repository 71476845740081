<template>
  <div class="container">
    <div class="header">
      <div class="header-left">
        <div class="header-left__title">
          {{ refShootStatus > '0' ? '查看出棚登记' : '出棚登记' }}
        </div>
        <div class="header-left__num">{{ selection.length }}</div>
      </div>
      <div v-if="!refShootStatus || refShootStatus <= '0'">
        <el-switch v-model="active" active-text="全选" @change="activeChange"> </el-switch>
        <el-button style="margin-left: 30px" type="primary" @click="openSampleAll"
          >选择其他样片</el-button
        >
      </div>
    </div>
    <div class="body">
      <div class="scroll">
        <div class="scroll-box">
          <div
            v-for="(item, index) in sampleList"
            :key="index"
            class="scroll-item"
            :class="[
              {
                'scroll-item--checked': isSelected(item),
              },
            ]"
            @click="refShootStatus > '0' ? '' : sampleItemChange(item)"
          >
            <el-image
              class="scroll-item__img"
              lazy
              :src="hanldeUrl(item.coverPictureUrl)"
              fit="cover"
            ></el-image>
            <div class="scroll-item__title">
              {{ item.sampleName }}
            </div>
            <div
              v-if="item.albums"
              class="el-icon-rank scroll-item__viewer"
              @click.stop="onViewer(item)"
            ></div>
            <div v-if="isSelected(item) || isSelected(item)" class="scroll-item__select">已拍</div>
          </div>
        </div>
      </div>

      <div v-if="refShootStatus > '0'" class="footer">
        <el-input
          v-model="remark"
          type="textarea"
          resize="none"
          maxlength="100"
          show-word-limit
          :rows="4"
          :disabled="true"
        >
        </el-input>
        <div class="footer-btn">
          <el-button size="small" @click="onClose">取消</el-button>
        </div>
      </div>
      <div v-else class="footer">
        <el-input
          v-model="remark"
          type="textarea"
          placeholder="请填写出棚总结"
          resize="none"
          maxlength="100"
          show-word-limit
          :rows="4"
        >
        </el-input>
        <div class="footer-btn">
          <el-button size="small" @click="onClose">取消</el-button>
          <el-button size="small" type="primary" @click="onSubmit">提交</el-button>
        </div>
      </div>
    </div>
    <SelectedSample
      :sample-visible.sync="sampleVisible"
      :search-form="{ storeId: storeId }"
      :selection="selection"
      @change="sampleChange"
    />
    <el-image-viewer v-if="showViewer" :url-list="urlList" :on-close="closeViewer" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { findDetailByServiceId, shootRegSave, shootPlan } from '@/api/order.js'
import { hanldeUrl } from '@/utils'
export default {
  components: { ElImageViewer },
  data() {
    return {
      sampleVisible: false,
      selection: [],
      sampleList: [],
      showViewer: false,
      urlList: [],
      serviceId: '',
      remark: '',
      active: false,
      photoRegistId: '',
      storeId: '',
      refShootStatus: '',
    }
  },
  computed: {
    isSelected() {
      return (item) => {
        return this.selection.findIndex((el) => el.sampleId === item.sampleId) !== -1
      }
    },
  },
  created() {
    this.serviceId = this.$route.query.serviceId
    this.storeId = this.$route.query.storeId
    this.refShootStatus = this.$route.query.shootStatus

    this.getData()
  },
  methods: {
    hanldeUrl,
    activeChange(e) {
      console.log(e)
      if (e) {
        this.selection = this.sampleList
        return
      }
      return (this.selection = [])
    },
    sampleChange(e) {
      console.log(e)
      let arr = e.filter((el) => !el.selectType)
      this.selection = e
      arr = arr.filter((el) => {
        if (this.sampleList.some((ev) => ev.sampleId === el.sampleId)) return false
        return true
      })
      this.sampleList.push(...arr)
    },
    sampleItemChange(e) {
      if (this.selection.findIndex((el) => el.sampleId === e.sampleId) !== -1) {
        this.selection = this.selection.filter((el) => el.sampleId !== e.sampleId)
        this.active = false
        return
      }
      this.selection.push(e)
      if (this.selection.length === this.sampleList.length) this.active = true
    },
    closeViewer() {
      this.showViewer = false
    },
    onViewer(item) {
      this.urlList = [item.coverPictureUrl]
      this.showViewer = true
    },
    openSampleAll() {
      this.sampleVisible = true
    },
    getData() {
      findDetailByServiceId({
        data: {
          serviceId: this.serviceId,
        },
      }).then((res) => {
        this.sampleList = res.data.relationSampleList
        this.remark = res.data.remark
        this.photoRegistId = res.data.photoRegistId
        this.selection = res.data.relationSampleList.filter((e) => e.selected === 1)
      })
    },
    onSubmit() {
      const selection = this.selection.map((e) => {
        return {
          sampleId: e.sampleId,
          sampleCode: e.sampleCode,
          selectType: e.selectType || 3,
          sampleName: e.sampleName,
          coverPictureUrl: e.coverPictureUrl,
        }
      })
      if (!selection.length) return this.$message.error('最少选择一个已拍摄的主题~')
      //  if (!this.remark) return this.$message.error('请填写出棚总结~')

      shootRegSave({
        data: {
          photoRegistId: this.photoRegistId,
          serviceId: this.serviceId,
          remark: this.remark,
          relationSampleList: selection,
        },
      })
        .then(() => {
          shootPlan({
            data: {
              serviceId: this.serviceId,
              shootSummary: this.remark,
              shootStatus: 0,
            },
          })
            .then(() => {
              this.$message.success('出棚登记成功~')
              this.onClose()
            })
            .catch((e) => {
              this.$message.error(e.description || '出棚登记失败~')
            })
        })
        .catch((e) => {
          this.$message.error(e.description)
        })
    },
    onClose() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  border-radius: 6px;
  margin: 10px 0 10px 0;
}
.header {
  height: 56px;
  border-radius: 6px 6px 0 0;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}
.header-left {
  display: flex;
  align-items: center;
  &__title {
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }
  &__num {
    color: #00a199;
    font-size: 14px;
    font-weight: 400;
    &::before {
      content: '已拍摄';
      color: #9a9d9e;
    }
    &::after {
      content: '套样片';
      color: #9a9d9e;
    }
  }
}
.scroll-box {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.scroll {
  height: 500px;
  overflow-y: auto;
  &-item {
    width: 111px;
    height: 141px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #fff;
    margin-right: 16px;
    margin-bottom: 16px;
    color: #131415;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    box-sizing: border-box;
    line-height: 0;
    position: relative;
    &--checked {
      background-color: #dae4fe;
      border: 1px solid #2861e2;
      color: #2861e2;
    }
    &--disabled {
      opacity: 0.4;
      color: #131415;
      border: 1px solid #fff;
      background-color: #fff;
    }
    &__img {
      width: 100%;
      height: 97px;
      border-radius: 4px;
    }
    &__title {
      height: 38px;
      width: 100%;
      text-align: center;
      line-height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__viewer {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      border-radius: 4px 4px 4px 4px;
      background: rgba(51, 51, 51, 0.3);
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__select {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 10;
      background-color: #2861e2;
      border-radius: 0px 0px 4px 0px;
      width: 32px;
      height: 16px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      &--disabled {
        background-color: #909399;
      }
    }
  }
}
.footer {
  border-top: 1px solid #ededed;
  padding: 12px;
  box-sizing: border-box;
  ::v-deep .el-textarea__inner {
    padding: 12px;
    box-sizing: border-box;
    background-color: #f5f6f9;
  }
  &-btn {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
::v-deep .el-textarea__inner {
  border: 0;
}
</style>
